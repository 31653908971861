/* eslint-disable new-cap */
import React from 'react';
import { useSelector } from '~/store/index.ts';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// eslint-disable-next-line import/no-unresolved
import { AgreeTermsAndConditions } from '~/components/AgreeTermsAndConditions';
import {
  ButtonGroup,
  Container,
  Title,
  Subtitle,
  DatePicker,
  Box,
} from '../styles';
import { Formik, Form, Field } from 'formik';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import * as FormStyles from '~/components/forms/styles';
import DateFnsUtils from '@date-io/date-fns';
import { formatISO } from 'date-fns';
import { useSnackbar } from '~/state/hooks/useSnackbar.ts';
import { useNavigate } from '~/state/hooks/useNavigate.ts';
import { useAccountState } from '~/state/hooks/useAccountState.ts';
import { mixed, object, number, date } from 'yup';
import { formFields } from '~/components/forms/constants';
import { WhereDidYouGo } from './CollapsedQuestions/WhereDidYouGo';
import { useDropDownQuestions } from './CollapsedQuestions/useDropDownQuestions';
import { MedicalTestType } from './CollapsedQuestions/MedicalTestType';
import { TimeToSeeProfessional } from './CollapsedQuestions/TimeToSeeProfessional';
import { AtHomeTest } from './AtHomeTest';
import { TestResults } from './AtHomeTest/TestResults';
import { Diagnosis } from './CollapsedQuestions/Diagnosis';
import { Prescription } from './CollapsedQuestions/Prescription';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { storeSubmissionId } from '~/common/storageUtils';
import { useUserDemographics } from '~/state/hooks/useUserDemographics.ts';
import { useHouseholdHealthReport } from '~/state/hooks/useHouseholdHealthReport.ts';
import { useUnwellReportInProgress } from '~/state/hooks/useUnwellReportInProgress.ts';
import { useHouseholdHealthReportState } from '~/state/hooks/useHouseholdHealthReportState.ts';
import { useUnwellReportSubmission } from '~/state/hooks/useUnwellReportSubmission.ts';
import { cleanUnwellReport } from '~/common/unwellReport.ts';

export const MedicalBackground = ({ location, saveStepValuesToStore }) => {
  const { step1 } = location.state || {};
  const theme = useTheme();
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const dispatch = useGlobalDispatch();
  const { showSnackbar } = useSnackbar();
  const { account } = useGlobalState().user;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { fetchAndUpdateAccountState } = useAccountState();
  const { loggedIn } = useSelector(state => state.userAuth);
  const { submitUnwellReport } = useUnwellReportSubmission();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const unwellStepTwoValues = useGlobalState().unwellFormDetails['step-2'];
  const { skipDemographicsForm } = useUserDemographics();
  const { setUnwellReportInProgress } = useUnwellReportInProgress();
  const { submitHouseholdUnwellReport } = useHouseholdHealthReport();
  const { clearHouseholdReportsState } = useHouseholdHealthReportState();
  const {
    headOfHousehold,
    householdMembers,
    unwellReportInProgress,
  } = useGlobalState().healthReport?.household;

  const isHouseholdReport = !!unwellReportInProgress;

  const tPath = 'UnwellForm';
  const tPathContext = unwellReportInProgress?.memberId
    ? { context: 'householdMember', name: unwellReportInProgress.memberName }
    : null;

  const getOtherOption = key => {
    return unwellStepTwoValues[key] ? unwellStepTwoValues[key].other : '';
  };

  const form = useDropDownQuestions({
    placesToGo: Object.keys(
      unwellStepTwoValues.health_professional_details || {}
    ),
    otherPlaceToGo: getOtherOption('health_professional_details'),
    medicalTest: Object.keys(unwellStepTwoValues.medical_test_details || {}),
    otherMedicalTest: getOtherOption('medical_test_details'),
    diagnosis: Object.keys(unwellStepTwoValues.diagnosis_details || {}),
    otherDiagnosis: getOtherOption('diagnosis_details'),
    prescription: Object.keys(unwellStepTwoValues.prescription_details || {}),
    otherPrescription: getOtherOption('prescription_details'),
  });

  const getStartedFeelingIllDate = () => {
    return unwellStepTwoValues.start_feeling_ill_date
      ? new Date(`${unwellStepTwoValues.start_feeling_ill_date}T00:00:00`)
      : new Date();
  };

  const convertSetToObject = (set, text) => {
    let ans = {};
    const reducer = (arr, key) => {
      key === 'OTHER' || key === 'other'
        ? (arr[key] = text)
        : (arr[key] = true);

      return arr;
    };
    ans = [...set].reduce(reducer, {});
    return ans;
  };

  const continueToNextStep = (formValues, recaptchaToken) => {
    navigate('unwell/demographics', {
      state: { recaptchaToken, step1, step2: formValues },
    });
  };

  const handleIndividualReport = async (values, recaptchaToken) => {
    saveStepValuesToStore(values);
    if (skipDemographicsForm) {
      const submissionValues = {
        ...values,
        birthdate: account.dateOfBirth,
        zipcode: account.zipcode,
        gender: account.gender,
        raceId: account.userRace?.race?.id,
        ethnicityId: account.userEthnicity?.ethnicity.id,
        userSubmittedRace: account.userRace?.userSubmittedRace,
        email: account.email,
      };
      const report = { step1, step2: submissionValues };
      const { userSubmissionId } = await submitUnwellReport(
        report,
        recaptchaToken
      );

      if (!userSubmissionId) {
        throw Error();
      }

      if (loggedIn) {
        navigate('thank-you', { replace: true });
      } else {
        storeSubmissionId(userSubmissionId);
        navigate('join', {
          replace: true,
          state: { comingFromHealthSubmission: true },
        });
      }
    } else {
      continueToNextStep(values, recaptchaToken);
    }
  };

  const handleHouseholdReport = async (values, recaptchaToken) => {
    const unwellReport = unwellReportInProgress.memberId
      ? householdMembers[unwellReportInProgress.memberId].unwellReport
      : headOfHousehold.unwellReport;

    const report = cleanUnwellReport({ ...unwellReport, ...values });
    report.email = account.email;
    await submitHouseholdUnwellReport(report, recaptchaToken);
    dispatch({
      type: 'MARK_UNWELL_REPORT_AS_SUBMITTED',
      payload: { memberId: unwellReportInProgress.memberId },
    });
    const nextUnwellReport = setUnwellReportInProgress();
    if (!nextUnwellReport) {
      // Get an array of household members with a wellSubmissionId
      const householdMemberSubmissionIds = Object.entries(householdMembers)
        .filter(([_, value]) => value.wellSubmissionId)
        .map(([householdMemberId, value]) => ({
          submissionId: value.wellSubmissionId,
          householdMemberId: Number(householdMemberId),
        }));
      if (householdMemberSubmissionIds.length) {
        navigate('household-bonus-questions', {
          replace: true,
          state: {
            wellSubmissionIds: {
              userSubmissionId: headOfHousehold.wellSubmissionId,
              householdMemberSubmissionIds,
            },
          },
        });
      } else if (headOfHousehold.healthStatus === 'WELL') {
        const { wellSubmissionId } = headOfHousehold;
        navigate('bonus-questions', {
          state: { wellSubmissionId },
          replace: true,
        });
      } else {
        navigate('thank-you', { replace: true });
      }
      clearHouseholdReportsState();
      await fetchAndUpdateAccountState();
    } else {
      navigate('unwell/symptoms');
    }
  };

  const submitForm = async formValues => {
    if (!executeRecaptcha) {
      return showSnackbar(t('ErrorHandling.recaptcha'), 'error');
    }
    try {
      const recaptchaToken = await executeRecaptcha('unwell');
      const values = normalizeValues(formValues, recaptchaToken);
      if (isHouseholdReport) {
        await handleHouseholdReport(values, recaptchaToken);
      } else {
        await handleIndividualReport(values, recaptchaToken);
      }
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode ?? 'ServerError';
      return showSnackbar(
        t(`ErrorHandling.submissionErrors.${errorCode}`),
        'error'
      );
    }
  };

  const getAtHomeTestValues = formValues => {
    const results = {};

    if (
      formValues[formFields.AT_HOME_TEST] &&
      formValues[formFields.AT_HOME_TEST].length
    ) {
      if (formValues[formFields.AT_HOME_TEST].includes('covid')) {
        results.covid = formValues.covid_test_results || '';
      }

      if (formValues[formFields.AT_HOME_TEST].includes('flu')) {
        results.flu = formValues.flu_test_results || '';
      }
    }

    return (
      Object.keys(results).length && { [formFields.AT_HOME_TEST]: results }
    );
  };

  const normalizeValues = formValues => {
    /* eslint-disable camelcase */
    const {
      at_home_test,
      covid_test_results,
      flu_test_results,
      ...values
    } = formValues;
    /* eslint-enable camelcase */
    return {
      ...values,
      [formFields.START_FEELING_ILL_DATE]: formatISO(
        values[formFields.START_FEELING_ILL_DATE],
        { representation: 'date' }
      ),
      [formFields.HEALTH_PROFESSIONAL_DETAILS]: convertSetToObject(
        form.placesToGo,
        form.otherPlaceToGo
      ),
      [formFields.MEDICAL_TEST_DETAILS]: convertSetToObject(
        form.medicalTest,
        form.otherMedicalTest
      ),
      [formFields.DIAGNOSIS_DETAILS]: convertSetToObject(
        form.diagnosis,
        form.otherDiagnosis
      ),
      [formFields.PRESCRIPTION_DETAILS]: convertSetToObject(
        form.prescription,
        form.otherPrescription
      ),
      ...getAtHomeTestValues(formValues),
    };
  };

  const initialValues = {
    [formFields.START_FEELING_ILL_DATE]: getStartedFeelingIllDate(),
    [formFields.SEEN_HEALTH_PROFESSIONAL]: '',
    [formFields.TIME_TO_SEE_HEALTH_PROFESSIONAL]:
      unwellStepTwoValues.time_to_see_professional || '',
    [formFields.HEALTH_PROFESSIONAL_DETAILS]: {},
    [formFields.MEDICAL_TEST_DETAILS]: {},
    [formFields.DIAGNOSIS_DETAILS]: {},
    [formFields.PRESCRIPTION_DETAILS]: {},
  };

  // If the START_FEELING_ILL_DATE is a string, we need to convert it into a Date object.
  if (typeof initialValues[formFields.START_FEELING_ILL_DATE] === 'string') {
    const [year, month, day] = initialValues[
      formFields.START_FEELING_ILL_DATE
    ].split('-');
    // Month is indexed at 0, so we need to remove 1 here to keep it right.
    initialValues[formFields.START_FEELING_ILL_DATE] = new Date(
      year,
      month - 1,
      day
    );
  }

  const validationSchema = object().shape({
    [formFields.TRAVEL_LOCATION]: mixed().when(
      formFields.TRAVELED_OUTSIDE_COUNTRY_LAST_14_DAYS,
      {
        is: 'yes',
        then: mixed().required(
          t('FormField.required', {
            field: `${t('FormField.travelLocation.label')}`,
          })
        ),
        otherwise: mixed().nullable(),
      }
    ),
    [formFields.DAYS_IN_QUARANTINE]: number().typeError(
      t('FormField.daysInQuarantine.validation.typeError')
    ),
    [formFields.START_FEELING_ILL_DATE]: date()
      .nullable()
      .typeError(t('FormField.startFeelingIll.validation.invalidDate'))
      .max(new Date(), t('FormField.startFeelingIll.validation.futureDate'))
      .min(
        new Date('11/01/2019'),
        t('FormField.startFeelingIll.validation.minDate')
      ),
  });

  return (
    <Container>
      <Box>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Title>{t(`${tPath}.medicalBackgroundHeading1`)}</Title>
          <Subtitle>
            {t(`${tPath}.medicalBackgroundHeading2`, { ...tPathContext })}
          </Subtitle>
          <Grid item xs={11} sm={10}>
            <Formik
              onSubmit={submitForm}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ setFieldValue, errors, values }) => (
                <Form style={{ marginTop: '30px' }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <>
                      <Grid container>
                        <FormStyles.Divider />
                        <FormStyles.InputContainer>
                          <FormStyles.Label>
                            {t(`${tPath}.startFeelingIll`, {
                              ...tPathContext,
                            })}
                          </FormStyles.Label>
                          <Grid>
                            <Field
                              disableFuture
                              disableToolbar
                              variant="inline"
                              format="yyyy-MM-dd"
                              inputVariant="outlined"
                              component={DatePicker}
                              maxDate={new Date()}
                              minDate={new Date('11/01/2019')}
                              name={formFields.START_FEELING_ILL_DATE}
                              value={values[formFields.START_FEELING_ILL_DATE]}
                              error={
                                !!errors[formFields.START_FEELING_ILL_DATE]
                              }
                              onChange={value =>
                                setFieldValue(
                                  formFields.START_FEELING_ILL_DATE,
                                  value,
                                  true
                                )
                              }
                              helperText={
                                errors[formFields.START_FEELING_ILL_DATE]
                              }
                              style={{
                                backgroundColor: 'transparent',
                                width: 300,
                              }}
                            />
                          </Grid>
                        </FormStyles.InputContainer>
                        <FormStyles.Divider />
                      </Grid>

                      <AtHomeTest
                        name={formFields.AT_HOME_TEST}
                        isHouseholdMemberReport={
                          !!unwellReportInProgress?.memberId
                        }
                      />
                      <FormStyles.Divider />
                      {values[formFields.AT_HOME_TEST] &&
                        values[formFields.AT_HOME_TEST]
                          .filter(atHomeTest => atHomeTest !== 'no')
                          .map(atHomeTest => (
                            <>
                              <TestResults
                                testType={atHomeTest}
                                isHouseholdMemberReport={
                                  !!unwellReportInProgress?.memberId
                                }
                                isMobile={isMobile}
                              />
                              <FormStyles.Divider />
                            </>
                          ))}

                      <WhereDidYouGo
                        questionLabel={t(
                          `${tPath}.whereYouSeeHealthProfessional`,
                          { ...tPathContext }
                        )}
                        isMobile={isMobile}
                        placeToGo={form.placesToGo}
                        selectPlaceToGo={form.selectPlaceToGo}
                        otherPlaceToGo={form.otherPlaceToGo}
                        setOtherPlaceToGo={form.setOtherPlaceToGo}
                      />
                      {form.placesToGo.size > 0 &&
                        !(
                          form.placesToGo.has('i_dont_seek_care') ||
                          form.placesToGo.has('unable_to_seek_care')
                        ) && (
                          <div data-test="medical_bg_extra_forms">
                            <TimeToSeeProfessional
                              questionLabel={t(
                                `${tPath}.TimeToSeeHealthProfessional.question`,
                                { ...tPathContext }
                              )}
                              isMobile={isMobile}
                              name={formFields.TIME_TO_SEE_HEALTH_PROFESSIONAL}
                            />
                            <MedicalTestType
                              isMobile={isMobile}
                              medicalTestSet={form.medicalTest}
                              selectMedicalTest={form.selectMedicalTest}
                              otherMedicalTest={form.otherMedicalTest}
                              setOtherMedicalTest={form.setOtherMedicalTest}
                            />
                            <Diagnosis
                              questionLabel={t(`${tPath}.diagnosisType`, {
                                ...tPathContext,
                              })}
                              isMobile={isMobile}
                              diagnosisSet={form.diagnosis}
                              selectDiagnosis={form.selectDiagnosis}
                              otherDiagnosis={form.otherDiagnosis}
                              setOtherDiagnosis={form.setOtherDiagnosis}
                            />
                            <Prescription
                              questionLabel={t(
                                `${tPath}.prescriptionQuestion`,
                                { ...tPathContext }
                              )}
                              isMobile={isMobile}
                              prescriptionSet={form.prescription}
                              selectPrescription={form.selectPrescription}
                              otherPrescription={form.otherPrescription}
                              setOtherPrescription={form.setOtherPrescription}
                            />
                          </div>
                        )}
                    </>
                  </MuiPickersUtilsProvider>
                  <Grid style={{ margin: '0px -12px' }}>
                    <ButtonGroup>
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{ margin: '30px auto 0' }}
                      >
                        <FormStyles.SubmitButton>
                          {skipDemographicsForm
                            ? t(`${tPath}.submit`)
                            : t(`${tPath}.actions.next`)}
                        </FormStyles.SubmitButton>
                      </Grid>
                    </ButtonGroup>
                  </Grid>

                  {Object.keys(errors).length > 0 && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <FormStyles.ErrorText>
                        {t('ErrorHandling.submissionErrors.ServerError')}
                      </FormStyles.ErrorText>
                    </Grid>
                  )}

                  {skipDemographicsForm && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <AgreeTermsAndConditions />
                    </Grid>
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
