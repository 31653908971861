import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl } from '@material-ui/core';
import { ColumnContainer } from '~/components/BaseElements/ColumnContainer';
import { useField } from 'formik';
import * as Styled from './styles';

interface Props {
  isHouseholdMemberReport: boolean;
  name: string;
}

export const AtHomeTest: React.FC<Props> = ({
  isHouseholdMemberReport,
  name,
}) => {
  const { t } = useTranslation();
  const [field] = useField(name);
  const translationPrefix = 'UnwellForm.atHomeTest';
  const options: [{ value: string; label: string }] = t(
    `${translationPrefix}.question.options`,
    {
      returnObjects: true,
    }
  );

  return (
    <Styled.Section data-test="at_home_test">
      <Styled.Question>
        {t(
          `${translationPrefix}.question.${
            isHouseholdMemberReport ? 'householdMember' : 'user'
          }`
        )}
      </Styled.Question>
      <FormControl
        fullWidth
        component="fieldset"
        style={{ alignItems: 'center' }}
        {...field}
      >
        <ColumnContainer columnCount={1} columnCountMobile={1} columnGap="2em">
          {options.map((option, index) => (
            <Styled.BaseInputLabel
              key={index}
              control={
                <Checkbox
                  data-test={`at_home_test_${option.value}`}
                  color="primary"
                  name={name}
                  value={option.value}
                />
              }
              label={option.label}
            />
          ))}
        </ColumnContainer>
      </FormControl>
    </Styled.Section>
  );
};
