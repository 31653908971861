import styled from 'styled-components';

interface Props {
  columnCount: number;
  columnCountMobile?: number;
  columnGap?: string;
}

export const ColumnContainer = styled.div<Props>`
  column-count: ${props => props.columnCount};
  column-gap: ${props => props.columnGap ?? 0};

  ${props => props.theme.breakpoints.down('sm')} {
    column-count: ${props => props.columnCountMobile ?? props.columnCount};
  }
`;
