/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Checkbox } from '@material-ui/core';
import { ColumnContainer } from '~/components/BaseElements/ColumnContainer';
import {
  BaseInputLabel,
  WhereDidYouGoSection,
  Question,
  BaseTypography,
  Divider,
  TextAreaGrid,
  TextArea,
  SelectChoiceGrid,
} from './styles';

interface Props {
  questionLabel: string;
  isMobile: boolean;
  onChange: (e: any) => void;
  placeToGo: Set<string>;
  selectPlaceToGo: (option: string) => void;
  otherPlaceToGo: string;
  setOtherPlaceToGo: (text: string) => void;
}

export const WhereDidYouGo: React.FC<Props> = ({
  questionLabel,
  isMobile,
  placeToGo,
  selectPlaceToGo,
  otherPlaceToGo,
  setOtherPlaceToGo,
}) => {
  const { t } = useTranslation();
  const translationPrefix = 'UnwellForm';
  const options: { label: string; value: string }[] = t(
    `${translationPrefix}.HealthProfessional.options`,
    {
      returnObjects: true,
    }
  );
  const handleOptionSelection = e => {
    const options = ['i_dont_seek_care', 'unable_to_seek_care'];
    options.forEach(option => {
      if (placeToGo.has(option)) {
        placeToGo.delete(option);
      }
      if (placeToGo.size > 0 && e.target.name === option) {
        placeToGo.clear();
      }
    });
    return selectPlaceToGo(e.target.value);
  };
  const renderOptionsCheckboxes = () =>
    options.map(({ value, label }, index) => (
      <div key={`health-professional-option-${index}`}>
        <BaseInputLabel
          label={label}
          control={
            <Checkbox
              value={value}
              name={value}
              onChange={handleOptionSelection}
              checked={placeToGo.has(value)}
              color="primary"
              data-test={`${value}_care_sought_checkbox`}
            />
          }
        />
      </div>
    ));
  return (
    <>
      <WhereDidYouGoSection>
        <Question gutterBottom>{questionLabel}</Question>
        <BaseTypography>
          {t(`${translationPrefix}.pleaseCheckAllApply`)}
        </BaseTypography>
        <FormControl
          fullWidth
          component="fieldset"
          style={{ alignItems: 'left' }}
        >
          <SelectChoiceGrid>
            <ColumnContainer
              columnCount={2}
              columnCountMobile={1}
              columnGap="2em"
            >
              {renderOptionsCheckboxes()}
            </ColumnContainer>
            {placeToGo.has('other') && (
              <TextAreaGrid>
                <TextArea
                  placeholder={t(`${translationPrefix}.additionalComments`)}
                  value={otherPlaceToGo}
                  onChange={e => setOtherPlaceToGo(e.target.value)}
                />
              </TextAreaGrid>
            )}
          </SelectChoiceGrid>
        </FormControl>
      </WhereDidYouGoSection>
      {isMobile && <Divider />}
    </>
  );
};
