import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import UnstyledMuiTextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const MuiTextField = styled(UnstyledMuiTextField)`
  .MuiInputBase-input {
    background-color: ${props => props.theme.palette.common.white};
  }
  .MuiOutlinedInput-root {
    padding: 0px;
  }
`;

const TextArea = props => {
  const [field, meta] = useField(props);
  return (
    <MuiTextField
      {...field}
      {...props}
      multiline
      rows={5}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
    />
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default TextArea;
