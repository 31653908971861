import { useState } from 'react';

type UseOptionSet = [Set<string>, (option: string) => void];

export const useOptionSet = (options?: string[]): UseOptionSet => {
  const [data, set] = useState(new Set<string>(options));

  const setData = (option: string) => {
    data.has(option) ? data.delete(option) : data.add(option);
    set(new Set(data));
  };

  return [data, setData];
};
