/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { ColumnContainer } from '~/components/BaseElements/ColumnContainer';
import { FormControl, Checkbox, Radio, RadioGroup } from '@material-ui/core';
import {
  BaseInputLabel,
  FormSection,
  Question,
  TextArea,
  RadioFormControl,
  BaseTypography,
  Divider,
  TextAreaGrid,
  SelectChoiceGrid,
} from './styles';

interface Props {
  isMobile: boolean;
  medicalTestSet: Set<string>;
  selectMedicalTest: (e: any) => void;
  otherMedicalTest: string;
  setOtherMedicalTest: (text: string) => void;
}

export const MedicalTestType: React.FC<Props> = ({
  isMobile,
  medicalTestSet,
  selectMedicalTest,
  otherMedicalTest,
  setOtherMedicalTest,
}) => {
  const { t } = useTranslation();
  const unwellReportInProgress = useGlobalState().healthReport?.household
    ?.unwellReportInProgress;

  const tPath = 'UnwellForm.MedicalTest';
  const tContext = unwellReportInProgress?.memberId
    ? { context: 'householdMember', name: unwellReportInProgress?.memberName }
    : null;

  const options: { label: string; value: string }[] = t(`${tPath}.options`, {
    returnObjects: true,
  });

  const handleOptionSelection = e => {
    return selectMedicalTest(e.target.value);
  };

  const renderOptionsCheckboxes = () =>
    options.map(({ label, value }, index) => (
      <div key={`medical-test-type-${index}`}>
        <BaseInputLabel
          label={label}
          control={
            <Checkbox
              color="primary"
              value={value}
              name={value}
              checked={medicalTestSet.has(value)}
              onChange={handleOptionSelection}
            />
          }
        />
      </div>
    ));

  return (
    <>
      <FormSection>
        <Question gutterBottom>
          {t(`${tPath}.question`, { ...tContext })}
        </Question>
        <BaseTypography>{t(`${tPath}.instructions`)}</BaseTypography>
        <FormControl
          fullWidth
          component="fieldset"
          style={{ alignItems: 'left' }}
        >
          <SelectChoiceGrid>
            <ColumnContainer
              columnGap="2em"
              columnCount={2}
              columnCountMobile={1}
            >
              {renderOptionsCheckboxes()}
            </ColumnContainer>
            {medicalTestSet.has('OTHER') && (
              <TextAreaGrid>
                <TextArea
                  value={otherMedicalTest}
                  onChange={e => setOtherMedicalTest(e.target.value)}
                  placeholder={t(`${tPath}.otherTestTypePlaceholder`)}
                />
              </TextAreaGrid>
            )}
          </SelectChoiceGrid>
        </FormControl>
      </FormSection>
      {medicalTestSet.has('AT_HOME_COVID_TEST') && (
        <FollowUpQuestion tContext={tContext} />
      )}
      {isMobile && <Divider />}
    </>
  );
};

interface FollowUpQuestionProps {
  tContext?: { context: string; name: string };
}

const FollowUpQuestion: React.FC<FollowUpQuestionProps> = ({ tContext }) => {
  const { t } = useTranslation();
  const [field] = useField('medicalTestFollowUp');

  const tPath = 'UnwellForm.MedicalTestFollowUp';

  const options: { label: string; value: string }[] = t(`${tPath}.options`, {
    returnObjects: true,
  });

  return (
    <FormSection>
      <Question gutterBottom>
        {t(`${tPath}.question`, { ...tContext })}
      </Question>
      <RadioFormControl>
        <RadioGroup id="medicalTestFollowUp" row={false} {...field}>
          {options.map(({ label, value }) => (
            <BaseInputLabel
              value={value}
              label={label}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
      </RadioFormControl>
    </FormSection>
  );
};
