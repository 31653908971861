/* eslint-disable @typescript-eslint/no-explicit-any*/
import { useState } from 'react';
import { useOptionSet } from '~/state/hooks/useOptionSet';

interface UseDropDownQuestions {
  placesToGo: Set<string>;
  selectPlaceToGo: (option: string) => void;
  otherPlaceToGo: string;
  setOtherPlaceToGo: (text: string) => void;

  medicalTest: Set<string>;
  selectMedicalTest: (option: string) => void;
  otherMedicalTest: string;
  setOtherMedicalTest: (text: string) => void;

  diagnosis: Set<string>;
  selectDiagnosis: (option: string) => void;
  otherDiagnosis: string;
  setOtherDiagnosis: (text: string) => void;

  prescription: Set<string>;
  selectPrescription: (option: string) => void;
  otherPrescription: string;
  setOtherPrescription: (text: string) => void;
}
interface Props {
  placesToGo?: string[];
  otherPlaceToGo?: string;
  medicalTest?: string[];
  otherMedicalTest?: string;
  diagnosis?: string[];
  otherDiagnosis?: string;
  prescription?: string[];
  otherPrescription?: string;
}

export const useDropDownQuestions = (props: Props): UseDropDownQuestions => {
  const [placesToGo, selectPlaceToGo] = useOptionSet(props?.placesToGo);
  const [medicalTest, selectMedicalTest] = useOptionSet(props?.medicalTest);
  const [diagnosis, selectDiagnosis] = useOptionSet(props?.diagnosis);
  const [prescription, selectPrescription] = useOptionSet(props?.prescription);
  const [otherMedicalTest, setOtherMedicalTest] = useState<string>(
    props?.otherMedicalTest
  );
  const [otherPlaceToGo, setOtherPlaceToGo] = useState<string>(
    props?.otherPlaceToGo
  );
  const [otherDiagnosis, setOtherDiagnosis] = useState<string>(
    props?.otherDiagnosis
  );
  const [otherPrescription, setOtherPrescription] = useState<string>(
    props?.otherPrescription
  );

  return {
    placesToGo,
    selectPlaceToGo,
    otherPlaceToGo,
    setOtherPlaceToGo,
    otherMedicalTest,
    setOtherMedicalTest,
    medicalTest,
    selectMedicalTest,
    diagnosis,
    selectDiagnosis,
    otherDiagnosis,
    setOtherDiagnosis,
    prescription,
    selectPrescription,
    otherPrescription,
    setOtherPrescription,
  };
};
