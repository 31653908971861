/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Checkbox } from '@material-ui/core';
import { ColumnContainer } from '~/components/BaseElements/ColumnContainer';
import {
  BaseInputLabel,
  FormSection,
  Question,
  TextArea,
  BaseTypography,
  Divider,
  TextAreaGrid,
  SelectChoiceGrid,
} from './styles';

interface PrescriptionOption {
  value: string;
  label: string;
}

interface Props {
  questionLabel: string;
  isMobile: boolean;
  prescriptionSet: Set<string>;
  selectPrescription: (option: string) => void;
  otherPrescription: string;
  setOtherPrescription: (text: string) => void;
}

export const Prescription: React.FC<Props> = ({
  questionLabel,
  isMobile,
  prescriptionSet,
  selectPrescription,
  otherPrescription,
  setOtherPrescription,
}) => {
  const { t } = useTranslation();
  const translationPrefix = 'UnwellForm';

  const handleOptionSelection = e => {
    return selectPrescription(e.target.value);
  };

  const prescriptionOptions: PrescriptionOption[] = t(
    'UnwellForm.Prescription.options',
    {
      returnObjects: true,
    }
  );

  const renderOptionsCheckboxes = () =>
    prescriptionOptions.map((option, index) => (
      <div key={`prescription-option-${index}`}>
        <BaseInputLabel
          label={option.label}
          control={
            <Checkbox
              name={option.value}
              value={option.value}
              onChange={handleOptionSelection}
              checked={prescriptionSet.has(option.value)}
              color="primary"
            />
          }
        />
      </div>
    ));
  return (
    <>
      <FormSection>
        <Question gutterBottom>{questionLabel}</Question>
        <BaseTypography>
          {t(`${translationPrefix}.pleaseCheckAllApply`)}
        </BaseTypography>
        <FormControl
          fullWidth
          component="fieldset"
          style={{ alignItems: 'left' }}
        >
          <SelectChoiceGrid>
            <ColumnContainer
              columnCount={2}
              columnCountMobile={1}
              columnGap="2em"
            >
              {renderOptionsCheckboxes()}
            </ColumnContainer>
            {prescriptionSet.has('other') && (
              <TextAreaGrid>
                <TextArea
                  placeholder={t(`${translationPrefix}.additionalComments`)}
                  value={otherPrescription}
                  onChange={e => setOtherPrescription(e.target.value)}
                />
              </TextAreaGrid>
            )}
          </SelectChoiceGrid>
        </FormControl>
      </FormSection>
      {isMobile && <Divider />}
    </>
  );
};
