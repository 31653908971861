import {
  Typography,
  FormControlLabel,
  FormControl,
  TextField,
  Theme,
  Grid,
} from '@material-ui/core';
import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import UnstyledDivider from '@material-ui/core/Divider';

export const BaseInputLabel = styled(FormControlLabel)`
  color: ${props => props.theme.palette.text.secondary};
  && {
    .MuiTypography-body1 {
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 500;
    }
  }
`;

export const Question = styled(Typography).attrs({
  align: 'center',
  gutterBottom: true,
})`
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 600;
  ${breakpoint.sm`
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
  `}
`;
export const TextAreaGrid = styled(Grid).attrs({
  container: true,
  justify: 'flex-start',
  item: true,
})`
  padding: 20px 0 30px 0;
`;

export const SelectChoiceGrid = styled(Grid)`
  padding: 0;
  ${breakpoint.sm` padding: 0 4rem 0 10rem;`}
`;

export const WhereDidYouGoSection = styled.section`
  padding: 1.5rem 0 1.5rem 0;
  ${breakpoint.sm` padding: 3rem 0 5rem 0;`}
`;

export const FormSection = styled.section`
  padding: 1.5rem 0 1.5rem 0;
  ${breakpoint.sm`padding-bottom: 5rem;`}
`;

export const BaseTypography = styled(Typography).attrs({
  align: 'center',
  gutterBottom: true,
  variant: 'subtitle2',
})`
  font-weight: 500;
  padding-bottom: 25px;
  ${breakpoint.sm`
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    padding-bottom: 25px;
  `}
`;

export const TextArea = styled(TextField).attrs({
  rows: 3,
  multiline: true,
  fullWidth: true,
  variant: 'outlined',
})`
  .MuiInputBase-root {
    background-color: ${props => props.theme.palette.common.white};
  }
  textarea {
    font-size: 16px;
    line-height: 20px;
    color: ${(props: { theme: Theme }) => props.theme.palette.text.secondary};
  }
`;

export const RadioFormControl = styled(FormControl).attrs({
  fullWidth: true,
  component: 'fieldset',
})`
  ${breakpoint.sm`
    align-items: center;
  `}
`;

export const Divider = styled(UnstyledDivider)`
  width: 100%;
  margin: ${props => `${props.theme.spacing(2)}px 0px`};
`;
