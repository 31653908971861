/* eslint-disable @typescript-eslint/no-explicit-any*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Checkbox } from '@material-ui/core';
import { ColumnContainer } from '~/components/BaseElements/ColumnContainer';
import {
  BaseInputLabel,
  FormSection,
  Question,
  TextArea,
  BaseTypography,
  Divider,
  TextAreaGrid,
  SelectChoiceGrid,
} from './styles';

interface Props {
  questionLabel: string;
  isMobile: boolean;
  diagnosisSet: Set<string>;
  selectDiagnosis: (option: string) => void;
  otherDiagnosis: string;
  setOtherDiagnosis: (text: string) => void;
}

export const Diagnosis: React.FC<Props> = ({
  questionLabel,
  isMobile,
  diagnosisSet,
  selectDiagnosis,
  otherDiagnosis,
  setOtherDiagnosis,
}) => {
  const { t } = useTranslation();

  const tPath = 'UnwellForm';

  const options: {
    label: string;
    value: string;
  }[] = t(`${tPath}.DiagnosisType.options`, { returnObjects: true });

  const handleOptionSelection = e => {
    return selectDiagnosis(e.target.value);
  };

  const renderOptionsCheckboxes = () =>
    options.map(({ label, value }, index) => (
      <div key={`diagnosis-option-${index}`}>
        <BaseInputLabel
          label={label}
          control={
            <Checkbox
              value={value}
              name={value}
              onChange={handleOptionSelection}
              checked={diagnosisSet.has(value)}
              color="primary"
            />
          }
        />
      </div>
    ));

  return (
    <>
      <FormSection>
        <Question gutterBottom>{questionLabel}</Question>
        <BaseTypography>{t(`${tPath}.pleaseCheckAllApply`)}</BaseTypography>
        <FormControl
          fullWidth
          component="fieldset"
          style={{ alignItems: 'left' }}
        >
          <SelectChoiceGrid>
            <ColumnContainer
              columnCount={2}
              columnCountMobile={1}
              columnGap="2em"
            >
              {renderOptionsCheckboxes()}
            </ColumnContainer>
            {diagnosisSet.has('other') && (
              <TextAreaGrid>
                <TextArea
                  placeholder={t(`${tPath}.additionalComments`)}
                  value={otherDiagnosis}
                  onChange={e => setOtherDiagnosis(e.target.value)}
                />
              </TextAreaGrid>
            )}
          </SelectChoiceGrid>
        </FormControl>
      </FormSection>
      {isMobile && <Divider />}
    </>
  );
};
