import styled from 'styled-components';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import breakpoint from '~/common/breakpoint';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextArea from '~/components/BaseElements/inputs/TextArea';

export const Badge = styled(DoneIcon)`
  background: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.common.white};
  border-radius: 100%;
  padding: 2px;
  position: absolute;
  top: -10px;
  right: -10px;
  opacity: 0;
  transition: 200;
`;

export const ButtonGroup = styled(ToggleButtonGroup)`
  background: transparent;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px;
  grid-row-gap: 10px;

  ${breakpoint.sm`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  `}
  ${breakpoint.md`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 80px;
  `}
  ${breakpoint.lg`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;

export const Icon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 8px;
  opacity: 0.5;

  ${breakpoint.md`
    margin-right: 16px;
  `}
`;

export const Button = styled(ToggleButton)`
  position: relative;
  border-width: 3px !important;
  border-style: solid !important;
  border-color: transparent;
  height: 100px;
  padding: 10px;
  background: ${props => props.theme.palette.common.white};
  transition: 0.2s;
  cursor: pointer;
  text-transform: capitalize;
  color: ${props => props.theme.palette.tertiary.main} !important;
  border-radius: 4px !important;
  font-size: 0.85rem;

  ${breakpoint.md`
    height: 80px;
  `}

  &.Mui-selected {
    background: ${props => props.theme.palette.common.white} !important;
    border-color: ${props => props.theme.palette.primary.main} !important;
    border-left-color: ${props => props.theme.palette.primary.main} !important;
    & svg {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    background: ${props => props.theme.palette.common.white};
    border-color: ${props => props.theme.palette.primary.main}; !important;
  }

  .MuiToggleButton-label {
    justify-content: flex-start;
  }

  p {
    margin: 0;
    align-self: center;
    text-align: left;
  }
`;

export const Temperature = styled(FormControl).attrs({
  variant: 'outline',
})`
  & .MuiInputAdornment-positionEnd + fieldset {
    border: none;
  }
`;

export const Category = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  margin-top: ${props => props.theme.spacing(4)}px;
  margin-bottom: ${props => props.theme.spacing(1.5)}px;
  text-align: center;
  text-transform: uppercase;
  ${breakpoint.sm`
    text-align: left;
    padding-left: ${props => props.theme.spacing(1.5)}px;
  `}
`;

export const FormNavigation = styled(Grid).attrs({
  container: true,
  justify: 'flex-end',
  item: true,
})`
  margin: 30px auto 0;
`;

export const OtherTextArea = styled(TextArea).attrs({
  variant: 'outlined',
  type: 'text',
})`
  maxwidth: 400px;
`;

export const OtherContainer = styled(Grid).attrs({
  container: true,
  item: true,
  sm: 10,
})``;
