import fever from '~/assets/images/symptoms/fever.svg';
import fatigue from '~/assets/images/symptoms/fatigue.svg';
import cough from '~/assets/images/symptoms/cough.svg';
import sneezing from '~/assets/images/symptoms/sneezing.svg';
import bodyPain from '~/assets/images/symptoms/body-pain.svg';
import runnyNose from '~/assets/images/symptoms/runny-nose.svg';
import sweat from '~/assets/images/symptoms/sweat.svg';
import soreThroat from '~/assets/images/symptoms/sore-throat.svg';
import diarrhea from '~/assets/images/symptoms/diarrhea.svg';
import headache from '~/assets/images/symptoms/headache.svg';
import breath from '~/assets/images/symptoms/breath.svg';
import nausea from '~/assets/images/symptoms/nausea.svg';
import rash from '~/assets/images/symptoms/rash.svg';
import lossOfSmell from '~/assets/images/symptoms/loss-of-smell.svg';
import lossOfAppetite from '~/assets/images/symptoms/loss-of-appetite.svg';
import stomachPain from '~/assets/images/symptoms/stomach-pain.svg';
import other from '~/assets/images/symptoms/other.svg';
import gaspingAir from '~/assets/images/symptoms/gasping-for-air.svg';
import dizziness from '~/assets/images/symptoms/dizziness.svg';
import vomit from '~/assets/images/symptoms/vomiting.svg';
import chestTightness from '~/assets/images/symptoms/chest-tightness.svg';
import earAche from '~/assets/images/symptoms/ear-ache.svg';
import pinkEye from '~/assets/images/symptoms/pink-eye.svg';

export const categories = [
  {
    name: 'wholebody',
    symptoms: [
      {
        value: 'chillsornightsweats',
        icon: sweat,
      },
      {
        value: 'achesandpains',
        icon: bodyPain,
      },
      {
        value: 'fatigue',
        icon: fatigue,
      },
      {
        value: 'fever',
        icon: fever,
      },
    ],
  },
  {
    name: 'respiratory',
    symptoms: [
      {
        value: 'cough',
        icon: cough,
      },
      {
        value: 'chesttightness',
        icon: chestTightness,
      },
      {
        value: 'sorethroat',
        icon: soreThroat,
      },
      {
        value: 'shortnessofbreath',
        icon: breath,
      },
      {
        value: 'lossofsmellortaste',
        icon: lossOfSmell,
      },
      {
        value: 'gaspingforair',
        icon: gaspingAir,
      },
      {
        value: 'runnyorstuffynose',
        icon: runnyNose,
      },
      {
        value: 'sneezing',
        icon: sneezing,
      },
    ],
  },
  {
    name: 'digestive',
    symptoms: [
      {
        value: 'stomachpainorcramp',
        icon: stomachPain,
      },
      {
        value: 'diarrhea',
        icon: diarrhea,
      },
      {
        value: 'vomiting',
        icon: vomit,
      },
      {
        value: 'lossofappetite',
        icon: lossOfAppetite,
      },
      {
        value: 'nausea',
        icon: nausea,
      },
    ],
  },
  {
    name: 'other',
    symptoms: [
      {
        value: 'dizziness',
        icon: dizziness,
      },
      {
        value: 'headaches',
        icon: headache,
      },
      {
        value: 'rash',
        icon: rash,
      },
      {
        value: 'pinkeyeorconjunctivitis',
        icon: pinkEye,
      },
      {
        value: 'earpain',
        icon: earAche,
      },
      {
        value: 'other',
        icon: other,
      },
    ],
  },
];
