import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '@material-ui/core';
import { useField } from 'formik';
import * as Styled from './styles';

interface Props {
  isHouseholdMemberReport: boolean;
  isMobile: boolean;
  testType: string;
}

export const TestResults: React.FC<Props> = ({
  isHouseholdMemberReport,
  isMobile,
  testType,
}) => {
  const { t } = useTranslation();
  const fieldName = `${testType}_test_results`;
  const [field] = useField(fieldName);
  const translationPrefix = 'UnwellForm.atHomeTest.testResults';
  const options: [{ value: string; label: string }] = t(
    `${translationPrefix}.answers`,
    {
      returnObjects: true,
    }
  );

  return (
    <Styled.Section>
      <Styled.Question>
        {t(
          `${translationPrefix}.${testType}.${
            isHouseholdMemberReport ? 'householdMember' : 'user'
          }`
        )}
      </Styled.Question>
      <Styled.RadioFormControl>
        <RadioGroup id={fieldName} row={!isMobile} {...field}>
          {options.map((option, index) => (
            <Styled.BaseInputLabel
              key={index}
              value={option.value}
              control={
                <Radio
                  data-test={`test_result_${testType}_${option.value}`}
                  color="primary"
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Styled.RadioFormControl>
    </Styled.Section>
  );
};
