import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useNavigate } from '~/state/hooks/useNavigate';
// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router';
import { useGlobalDispatch } from '~/context/global';
import { SymptomsSelector } from './SymptomSelector';
import { MedicalBackground } from './MedicalBackground';
import { Demographics } from '../Demographics';
import { ReportType } from '~/context/global/reports';

interface Props extends PageProps {
  location: Location & {
    state: {
      recaptchaToken?: string;
      step1?: unknown;
      step2?: unknown;
    };
  };
}

export const UnwellFlow: React.FC<Props> = props => {
  const { navigate } = useNavigate();
  const dispatch = useGlobalDispatch();

  const step1 = props.path.endsWith('/unwell/symptoms');
  const step2 = props.path.endsWith('/unwell/medical-background');
  const step3 = props.path.endsWith('/unwell/demographics');

  const recaptchaToken = props.location?.state?.recaptchaToken;

  const saveStepValuesToStore = (step: number) => {
    return formDetails => {
      dispatch({
        type: 'SET_UNWELL_FORM_DETAILS',
        payload: { formDetails, stepName: `step-${step}` },
      });
    };
  };

  const clearUnwellForms = () => {
    dispatch({ type: 'RESET_UNWELL_FORM_DETAILS', payload: {} });
  };

  useEffect(() => {
    const removeListener = globalHistory.listen(({ location }) => {
      const userLeftUnwellFlow = !location.href.includes('unwell');
      if (userLeftUnwellFlow) clearUnwellForms();
    });

    return function cleanup() {
      removeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step1) {
    return (
      <SymptomsSelector
        saveStepValuesToStore={saveStepValuesToStore(1)}
        {...props}
      />
    );
  }

  if (step2 && recaptchaToken) {
    return (
      <MedicalBackground
        saveStepValuesToStore={saveStepValuesToStore(2)}
        {...props}
      />
    );
  }

  if (step3 && recaptchaToken) {
    return (
      <Demographics
        reportType={ReportType.UNWELL}
        saveStepValuesToStore={saveStepValuesToStore(3)}
        {...props}
      />
    );
  }

  navigate('');
  return <></>;
};
