import React from 'react';
import { Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Question,
  FormSection,
  BaseInputLabel,
  RadioFormControl,
  Divider,
} from './styles';
import { useField } from 'formik';

export enum TimeToSeeProfessionalAnswer {
  TWO_DAYS_OR_LESS = '2 days or less',
  THREE_TO_SEVEN_DAYS = '3-7 days',
  EIGHT_DAYS_OR_MORE = '8 days or more',
}

interface Props {
  questionLabel: string;
  isMobile: boolean;
  name: string;
}

export const TimeToSeeProfessional: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [field] = useField(props.name);
  const translationPrefix = 'UnwellForm.TimeToSeeHealthProfessional';

  return (
    <>
      <FormSection>
        <Question gutterBottom>{props.questionLabel}</Question>
        <RadioFormControl>
          <RadioGroup id={props.name} row={!props.isMobile} {...field}>
            <BaseInputLabel
              value={TimeToSeeProfessionalAnswer.TWO_DAYS_OR_LESS}
              control={<Radio color="primary" />}
              label={t(`${translationPrefix}.answer.0`)}
            />
            <BaseInputLabel
              value={TimeToSeeProfessionalAnswer.THREE_TO_SEVEN_DAYS}
              control={<Radio color="primary" />}
              label={t(`${translationPrefix}.answer.1`)}
            />
            <BaseInputLabel
              value={TimeToSeeProfessionalAnswer.EIGHT_DAYS_OR_MORE}
              control={<Radio color="primary" />}
              label={t(`${translationPrefix}.answer.2`)}
            />
          </RadioGroup>
        </RadioFormControl>
      </FormSection>
      {props.isMobile && <Divider />}
    </>
  );
};
