import styled from 'styled-components';
import { FormControl, FormControlLabel, Typography } from '@material-ui/core';
import breakpoint from '~/common/breakpoint';

export const Section = styled.section`
  padding: 1.5rem 0 1.5rem 0;
  ${breakpoint.sm`
    padding: 2rem 0 2rem 0;
  `}
`;

export const Question = styled(Typography).attrs({
  align: 'center',
  gutterBottom: true,
})`
  line-height: 25.6px;
  font-weight: 600;
  ${breakpoint.sm`
      font-size: 18px;
      font-weight: 400;
      line-height: 21.94px;
    `}
`;

export const RadioFormControl = styled(FormControl).attrs({
  fullWidth: true,
  component: 'fieldset',
})`
  ${breakpoint.sm`
    align-items: center;
  `}
`;

export const BaseInputLabel = styled(FormControlLabel)`
  color: ${props => props.theme.palette.text.secondary};
  && {
    .MuiTypography-body1 {
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 500;
    }
  }
`;
