import { omitBy, isNil } from 'lodash';
import { UnwellReport } from '~/context/global/reports/types';

export const cleanUnwellReport = (report: UnwellReport): UnwellReport => {
  const copy = omitBy(report, isNil) as UnwellReport;

  if (!copy.feverdetails?.measured || !copy.symptoms?.fever) {
    delete copy.feverdetails;
  }

  return copy;
};
