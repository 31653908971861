import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import { Typography, Box as UnstyledBox } from '@material-ui/core';
import { DatePicker as UnstyledDatePicker } from '~/components/forms/fields/DatePicker.tsx';
import { P } from '~/components/BaseElements';

export const Box = styled(UnstyledBox).attrs({
  py: 8,
  mx: 3,
})``;

export const Container = styled.div`
  background-color: ${props => props.theme.palette.background.main};
  min-height: 800px;
  padding-top: 75px;
  ${breakpoint.md`
  padding-top: 90px;
  `}
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
`;

export const Subtitle = styled(P).attrs({
  variant: 'h3',
})`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 18px;
  line-height: 1.6rem;
  font-weight: 400;
  margin: 0 auto;
  ${breakpoint.md`
  max-width: 60%;
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 30px;
`;

export const DatePicker = styled(UnstyledDatePicker)`
  .MuiOutlinedInput-adornedEnd {
    background-color: ${props => props.theme.palette.common.white};
  }
`;
