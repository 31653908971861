import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { UnwellFlow } from '~/components/forms/Unwell';
import { withVerifyEmailSource } from '~/HOC/VerifyEmailSource';

const Unwell: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="UnwellForm.pageTitle" />
    <UnwellFlow {...props} />
  </>
);

export default withVerifyEmailSource(Unwell);
